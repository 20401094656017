var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('PageHeader',{attrs:{"title":_vm.$tc('generic-str.plan', 2)},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('li',[_c('button',{staticClass:"btn btn-success btn-floating",on:{"click":function($event){return _vm.$refs['plan-modal'].toggle()}}},[_c('i',{staticClass:"la la-plus-circle text-white font-size-22 v-align-text-bottom"})])])]},proxy:true}])}),_c('section',{staticClass:"page-content container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"title":_vm.$tc('generic-str.plan', 2),"fetchable":"","fetched":_vm.fetched}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{attrs:{"columns":[
                  {
                    key: 'service',
                    class: 'badge badge-primary',
                    label: 'type',
                  },
                  'name',
                  {
                    key: 'pivot.pricing.fee',
                    label: 'estimate_price',
                    filters: ['currency'],
                  },
                ],"items":_vm.plans},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('router-link',{attrs:{"to":{ name: '', params: { planId: item.id } }}},[_c('i',{staticClass:"la la-eye"})])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('pagination',{attrs:{"lastPage":_vm.lastPage}})],1)])])],1)])]),_c('modal',{ref:"plan-modal",attrs:{"title":_vm.$t('generic-str.new-plan')},on:{"submit":_vm.store}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('name'))+" ")]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('type'))+" ")]),_c('div',{staticClass:"col-sm-10"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.service),expression:"form.service"}],staticClass:"form-control",attrs:{"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "service", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"sms","selected":""}},[_vm._v("SMS")]),_c('option',{attrs:{"value":"omni"}},[_vm._v("Chat")]),_c('option',{attrs:{"value":"whatsapp"}},[_vm._v("Whatsapp API")]),_c('option',{attrs:{"value":"rcs"}},[_vm._v("Google RCS")]),_c('option',{attrs:{"value":"email"}},[_vm._v("E-Mail")]),_c('option',{attrs:{"value":"lookup"}},[_vm._v("Lookup")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }